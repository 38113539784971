const loc = window.location.href;
export const baseUrl = `${
    loc.includes(`localhost`)
        ? `https://localhost:8443`
        : loc.includes(`-develop`)
        ? `https://8ag3nyhf6a.execute-api.us-east-1.amazonaws.com/default`
        : loc.includes(`-stage`)
        ? `https://o3jcgwp676.execute-api.us-east-1.amazonaws.com/default`
        : `https://6o9daekark.execute-api.us-east-1.amazonaws.com/default`
}/api/`;

export const currentEnv = loc.includes(`localhost`)
    ? `localhost`
    : loc.includes(`-develop`)
    ? `develop`
    : loc.includes(`-stage`)
    ? `stage`
    : `production`;
