import { Box, Divider, FormControl, MenuItem, Select, styled, useMediaQuery, useTheme } from '@mui/material';
import emStyled from '@emotion/styled';
import FlexBetween from 'components/flexbox/FlexBetween';
import FlexBox from 'components/flexbox/FlexBox';

import { useEffect, useState } from 'react';

import MultiLevelMenu from './MultiLevelMenu';
import MobileSidebar from './MobileSidebar';
import { clearLocalStorage, localSet } from 'utils/utils';
import axiosInstance from 'utils/axios';
import { baseUrl } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
const TOP_HEADER_AREA = 70;
const SidebarWrapper = styled(Box)(({ theme, compact }) => ({
    height: `100vh`,
    position: `fixed`,
    width: compact ? 86 : 280,
    left: 0,
    transition: `all .2s ease`,
    zIndex: theme.zIndex.drawer,
    backgroundColor: theme.palette.background.paper,
    '&:hover': compact && {
        width: 280,
    },
}));
const NavWrapper = styled(Box)(() => ({
    paddingLeft: 16,
    paddingRight: 16,
    height: `100%`,
}));

const LogoImg = emStyled.img`
    height: ${({ lilogo }) => (lilogo ? `40` : `35`)}px;
    margin: ${({ lilogo }) => (lilogo ? `10` : `12`)}px 0 0 0;
    width:auto;
`;

// -----------------------------------------------------------------------------
const DashboardSidebar = props => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { sidebarCompact, showMobileSideBar, setShowMobileSideBar } = props;

    const [config, setConfig] = useState(JSON.parse(localStorage.getItem(`config`) || JSON.stringify({})));
    const [allPrograms, setAllPrograms] = useState(
        JSON.parse(localStorage.getItem(`allPrograms`) || JSON.stringify([]))
    );
    const [selectedProgram, setSelectedProgram] = useState(localStorage.getItem(`selectedProgram`));
    const downLg = useMediaQuery(theme => theme.breakpoints.down(`lg`)); // Activate compact when toggle button clicked and not on hover state

    const Separator = emStyled.div`
    height: 40px;
    width: 1px; 
    background-color: ${theme.palette.divider};
    margin: 10px 45px;`;

    const listenerUpdate = () => {
        setAllPrograms(JSON.parse(localStorage.getItem(`allPrograms`) || JSON.stringify([])));
        setSelectedProgram(localStorage.getItem(`selectedProgram`));
        setConfig(JSON.parse(localStorage.getItem(`config`) || JSON.stringify({})));
    };

    const switchProgram = v =>
        axiosInstance
            .get(`${baseUrl}switchme?guid=${v}`)
            .then(r => {
                Object.keys(r.data).forEach(i => {
                    localSet(i, r.data[i]);
                });
                window.location.href = `/dashboard/research`;
            })
            .catch(() => {
                clearLocalStorage();
                navigate(`/login`);
            });

    useEffect(() => {
        window.addEventListener(`storage`, listenerUpdate);
        return () => window.removeEventListener(`storage`, listenerUpdate);
    }, []);

    if (downLg) {
        return (
            <MobileSidebar
                sidebarCompact={false}
                showMobileSideBar={!!showMobileSideBar}
                setShowMobileSideBar={setShowMobileSideBar}
            />
        );
    }

    return (
        <SidebarWrapper compact={sidebarCompact ? 1 : 0}>
            <FlexBetween pt={3} pr={2} pl={4} pb={1} height={TOP_HEADER_AREA}>
                {/* LOGO */}
                <FlexBox>
                    <LogoImg lilogo src="/static/logo/lilogo.svg" alt="logo" />
                    <Separator />

                    <LogoImg alt="logo" src={config?.li_dashboard?.headerlogo || ``} />
                </FlexBox>
                <Box mx={`auto`}></Box>
            </FlexBetween>
            {!!allPrograms.length && (
                <FlexBetween pt={3} pr={2} pl={4} pb={1} height={TOP_HEADER_AREA}>
                    <FormControl fullWidth>
                        <Select
                            sx={{
                                fontSize: `14px`,
                                fontWeight: `500`,
                                color: `#5F748D`,
                                '&:hover': { background: `#F7F7F7` },
                                '& div ': { padding: `7px 12px !important` },
                                minHeight: `unset !important`,
                                borderRadius: `20px !important`,
                            }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth
                            value={selectedProgram}
                            onChange={v => switchProgram(v.target.value)}
                        >
                            {localStorage.programs}
                            {allPrograms.map((a, i) => (
                                <MenuItem
                                    key={i}
                                    sx={{ fontSize: `14px`, fontWeight: `500`, color: `#5F748D` }}
                                    value={a.programGuid}
                                >
                                    {a.programName}
                                </MenuItem>
                            ))}
                            <Divider />
                            <MenuItem
                                sx={{ fontSize: `14px`, fontWeight: `500`, color: `#5F748D` }}
                                value="allprograms"
                            >
                                All Programs
                            </MenuItem>
                        </Select>
                    </FormControl>
                </FlexBetween>
            )}
            {/* NAVIGATION ITEMS */}

            <NavWrapper>
                <MultiLevelMenu sidebarCompact={false} />
            </NavWrapper>
        </SidebarWrapper>
    );
};

export default DashboardSidebar;
